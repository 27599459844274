//function to create a timeseries for the sunwatch, showing a line chart with the sun angle over time starting at
//00:00 with the min sun angle hitting at 12:00 and the max sun angle and having the min sun angle again at 24:00
//in 5minute intervals respecting the sun angle traveling time. getting the angle parameters as function parameters
//and returning the timeseries as an array of objects with the time and the sun angle and a label for the time
import { getSunrise, getSunset } from "sunrise-sunset-js";

export const getSunTimeSeries = (
  maxSunAngle: number,
  minSunAngle: number,
  switchTimes: dimmingProfileDisplay[]
) => {
  const sunAngleMovementPer5min = 1.25;
  let sunAngle = minSunAngle;
  let minutes = 0;
  const sortedSwitchTimes = switchTimes.sort((a, b) =>
    a.time.localeCompare(b.time)
  );

  const timeSeries = Array.from({ length: 288 }, (_, i) => {
    const hoursLabel = Math.floor(minutes / 60);
    const minutesLabel = minutes % 60;

    const displaySunAngle = Math.sin(sunAngle * (Math.PI / 180)) + 1;
    minutes += 5;

    //retreive the percent value from switchTimes based on the current time. meaning the last hour before the hours label determines
    //the percent value for the current time
    let percent = sortedSwitchTimes.reduce((acc, time) => {
      const timeSplit = time.time.split(":");
      const timeMinutes = parseInt(timeSplit[0]) * 60 + parseInt(timeSplit[1]);
      if (timeMinutes <= minutes) {
        return time.percent;
      } else {
        return acc;
      }
    }, -1);

    if (percent === -1) {
      //no percent found
      percent = sortedSwitchTimes[sortedSwitchTimes.length - 1]?.percent || 0;
    }

    if (hoursLabel < 12) {
      sunAngle = Math.min(maxSunAngle, sunAngle + sunAngleMovementPer5min);
    } else {
      sunAngle = Math.max(minSunAngle, sunAngle - sunAngleMovementPer5min);
    }

    //assign the hours and minutes in the HH:MM format as the time label
    return {
      time: `${hoursLabel < 10 ? "0" + hoursLabel : hoursLabel}:${
        minutesLabel < 10 ? "0" + minutesLabel : minutesLabel
      }`,
      hourAngle: displaySunAngle,
      baseline: 0,
      [percent]: displaySunAngle,
    };
  });
  return timeSeries;
};
export const convertDateToHHMM = (date: Date, fiveMinute = true) => {
  const hours = date.getHours();

  const minutes = fiveMinute
    ? Math.floor(date.getMinutes() / 5) * 5
    : date.getMinutes();
  return `${hours < 10 ? "0" + hours : hours}:${
    minutes < 10 ? "0" + minutes : minutes
  }`;
};
export const getSunriseSunset = (lat: number, lng: number, date: Date) => {
  const sunrise = convertDateToHHMM(getSunrise(lat, lng, date));
  const sunset = convertDateToHHMM(getSunset(lat, lng, date));
  return { sunrise, sunset };
};
export const substractHHMMfromHHMM = (time1: string, time2: string) => {
  const time1Split = time1.split(":");
  const time2Split = time2.split(":");
  const time1Minutes = parseInt(time1Split[0]) * 60 + parseInt(time1Split[1]);
  const time2Minutes = parseInt(time2Split[0]) * 60 + parseInt(time2Split[1]);
  return time1Minutes - time2Minutes;
};
export const findHHMMCloserToHHMM = (
  time1: string,
  time2: string,
  time3: string
) => {
  const time1Split = time1.split(":");
  const time2Split = time2.split(":");
  const time3Split = time3.split(":");
  const time1Minutes = parseInt(time1Split[0]) * 60 + parseInt(time1Split[1]);
  const time2Minutes = parseInt(time2Split[0]) * 60 + parseInt(time2Split[1]);
  const time3Minutes = parseInt(time3Split[0]) * 60 + parseInt(time3Split[1]);
  if (
    Math.abs(time1Minutes - time3Minutes) <
    Math.abs(time2Minutes - time3Minutes)
  ) {
    return time1;
  } else {
    return time2;
  }
};

export interface dimmingProfile {
  type: "absolute" | "relative";
  timeInMinutes: number;
  relativeTo: "sunrise" | "sunset" | "";
  percent: number;
}

export interface dimmingProfileDisplay extends dimmingProfile {
  time: string;
}

export const getDisplayTimeFromDimmingProfile = (
  dimmingProfile: dimmingProfile,
  sunrise: string,
  sunset: string
): string => {
  const time = dimmingProfile.timeInMinutes;
  if (dimmingProfile.type === "absolute") {
    const hours = Math.floor(time / 60);
    const minutes = time % 60;
    return `${hours < 10 ? "0" + hours : hours}:${
      minutes < 10 ? "0" + minutes : minutes
    }`;
  } else {
    const referenceTime =
      dimmingProfile.relativeTo === "sunrise" ? sunrise : sunset;
    const referenceTimeSplit = referenceTime.split(":");
    const referenceTimeMinutes =
      parseInt(referenceTimeSplit[0]) * 60 + parseInt(referenceTimeSplit[1]);
    const newTime = referenceTimeMinutes + time;
    const hours = Math.floor(newTime / 60);
    const minutes = newTime % 60;
    return `${hours < 10 ? "0" + hours : hours}:${
      minutes < 10 ? "0" + minutes : minutes
    }`;
  }
};
export const convertTimeToMinutes = (time: string) => {
  const timeSplit = time.split(":");
  return parseInt(timeSplit[0]) * 60 + parseInt(timeSplit[1]);
};
export const convertRelativeTimeToMinutes = (
  time: string,
  referenceTime: string
) => {
  console.log(time, referenceTime);
  const referenceTimeSplit = referenceTime.split(":");
  const referenceTimeMinutes = convertTimeToMinutes(referenceTime);
  return convertTimeToMinutes(time) - referenceTimeMinutes;
};
