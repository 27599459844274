import { useTranslation } from "../../../../MaterialOnFire/custom-hooks/useTranslation";
import { Box, Grid, TextField, useEventCallback } from "@mui/material";
import { TCreateLoraNodeDataModel } from "../CreateLoraNodeWizardWrapper";
import React, {useCallback, useEffect, useRef, useState} from "react";
import { useSnackbar } from "notistack";
import { GenericSelect } from "../../../../MaterialOnFire/GenericUIFields/GenericSelect/GenericSelect";
import { UiDescriptor } from "../../../../MaterialOnFire/GenericUIFields/MaintenanceView";
import KapionMap from "../../../../map/Map";
import { MapTileProvider } from "../../../../map/MapTileProvider";
import { NodeModalMapHandler } from "../../../../nodeDetails/NodeModal/NodeModalMapHandler";
import { MapContainer } from "react-leaflet";
import { PlaceDeviceMapHandler } from "./PlaceDeviceMapHandler";

export const ProvideDeviceDetailsStep = (props: {
  newLoraNode: TCreateLoraNodeDataModel;
  updateNewLoraNode: (newState: TCreateLoraNodeDataModel) => void;
}) => {
  const { t } = useTranslation();
  const getNewLoraNode = useEventCallback(() => {
    return props.newLoraNode;
  });

  const { enqueueSnackbar } = useSnackbar();
  const [coordinatesTakenOver, setCoordinatesTakenOver] = useState(false);
  const [browserLocation, setBrowserLocation] = useState({ lat: 0, lng: 0 });
  const hardwareTypeUiDefinition = {
    fieldName: "hardwareType",
    required: true,
    reference: {
      id: "HardwareTypeUIDescriptor",
      model: "hardwareTypes",
    },
  } as UiDescriptor;

  const groupUiDefinition = {
    fieldName: "group",
    required: false,
    reference: {
      id: "GroupUIDescriptor",
      model: "groups",
    },
  } as UiDescriptor;

  useEffect(() => {
    if (navigator?.geolocation) {
      enqueueSnackbar(
        t(
          "createLoraNodeWizard.provideDeviceDetailsStep.tryToGetGeoCoordinates"
        ),
        { variant: "info" }
      );
    }
    const watchID = navigator?.geolocation?.watchPosition(
      (position) => {
        setCoordinatesTakenOver(true);
        console.log("took coordinates over");
        setTimeout(() => setCoordinatesTakenOver(false), 1500);

        setBrowserLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      () => {
        enqueueSnackbar(
          t(
            "createLoraNodeWizard.provideDeviceDetailsStep.GeoCoordinatesFailed"
          ),
          { variant: "error" }
        );
      },
      {}
    );

    return () => navigator?.geolocation?.clearWatch(watchID);
  }, []);


  const updateLoraNode = useCallback((lat: number, lng:number) => {
    props.updateNewLoraNode({
      ...props.newLoraNode,
      lat: lat + ``,
      lng: lng + ``,
    });
  }, [props.newLoraNode, props.updateNewLoraNode])

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      height={"100%"}
      width={"100%"}
    >
      <Grid container spacing={2} sx={{ p: 1 }}>
        <Grid item xs={6}>
          <TextField
            required={true}
            fullWidth
            focused={coordinatesTakenOver}
            label={t("createLoraNodeWizard.provideDeviceDetailsStep.lat")}
            value={props.newLoraNode?.lat || ""}
            onChange={(e) => {
              props.updateNewLoraNode({
                ...props.newLoraNode,
                lat: e.currentTarget.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required={true}
            fullWidth
            focused={coordinatesTakenOver}
            label={t("createLoraNodeWizard.provideDeviceDetailsStep.lng")}
            value={props.newLoraNode?.lng || ""}
            onChange={(e) => {
              props.updateNewLoraNode({
                ...props.newLoraNode,
                lng: e.currentTarget.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <GenericSelect
            key={hardwareTypeUiDefinition.fieldName}
            translationPrefix={"loraNodes"}
            fieldKey={hardwareTypeUiDefinition.fieldName}
            value={props.newLoraNode?.hardwareType || ""}
            uiDescriptor={hardwareTypeUiDefinition}
            required={hardwareTypeUiDefinition.required}
            onChange={(value) => {
              props.updateNewLoraNode({
                ...props.newLoraNode,
                hardwareType: value,
              });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <GenericSelect
            key={groupUiDefinition.fieldName}
            translationPrefix={"loraNodes"}
            fieldKey={groupUiDefinition.fieldName}
            value={props.newLoraNode?.group || ""}
            uiDescriptor={groupUiDefinition}
            required={groupUiDefinition.required}
            onChange={(value) => {
              props.updateNewLoraNode({
                ...props.newLoraNode,
                group: value,
              });
            }}
          />
        </Grid>
      </Grid>

      <Box id={"map"} width={"100%"} height="75%">
        {browserLocation.lng && browserLocation.lat ? (
          <MapContainer
            center={[browserLocation.lat, browserLocation.lng]}
            zoom={19}
            maxZoom={25}
            scrollWheelZoom={true}
            className="markercluster-map"
            style={{ height: "100%", width: "100%" }}
          >
            <MapTileProvider />
            <PlaceDeviceMapHandler
              browserLocation={browserLocation}
              deviceLocation={{
                lat: parseFloat(
                  props.newLoraNode.lat || browserLocation.lat + ``
                ),
                lng: parseFloat(
                  props.newLoraNode.lng || browserLocation.lng + ``
                ),
              }}
              updateDeviceLocation={updateLoraNode}
            />
          </MapContainer>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};
